import { ethers } from 'ethers';

import zkfairStakeAbi from './abi/zkfair/stake.json';

export const chains = [
  {
    id: '42766',
    name: 'ZKFair Mainnet',
    nativeCurrency: { name: 'USDC', symbol: 'USDC', decimals: 18 },
    rpcUrls: ['https://rpc.zkfair.io'],
    explorerUrl: 'https://scan.zkfair.io/',
    features: {
      spinValue: 0,
      mintValue: 1.5,
      stakeValue: 1.5,
      unStakeValue: 1.5,
      apiStaking: false,
    },
    contracts: {
      // Entrypass: new ethers.Contract(''),
      NFT: new ethers.Contract(
        '0x8A2E7143f1b153f454836c2fef5d53Fe6d8bB02B',
        zkfairStakeAbi
      ),
      Stake: new ethers.Contract(
        '0x8A2E7143f1b153f454836c2fef5d53Fe6d8bB02B',
        zkfairStakeAbi
      ),
      //Store: new ethers.Contract(''),
    },
  },
];
