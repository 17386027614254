import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from '@web3modal/ethers5/react';
import { createContext, useCallback, useMemo, useState } from 'react';
import { providers } from 'ethers';
import { chains } from '../services/chains';
import { chainIdToHex, handleTransactionError } from '../services/util';
import { toast } from 'react-toastify';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [selectedChainId, setSelectedChainId] = useState();
  const { open } = useWeb3Modal();

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const provider = useMemo(() => {
    if (!walletProvider) return null;
    return new providers.Web3Provider(walletProvider, 'any');
  }, [walletProvider]);

  const switchChain = useCallback(
    async (id) => {
      if (!provider) return open();

      const chain = chains.find((item) => item.id == id);
      if (!chain) {
        throw new Error(`Invalid chain id '${id}`);
      }

      try {
        await provider.send('wallet_switchEthereumChain', [
          { chainId: chainIdToHex(+id) },
        ]);

        setSelectedChainId(id);
        return id;
      } catch (switchError) {
        try {
          var parsed = JSON.parse(switchError);
        } catch (err) {}

        if (parsed?.data?.originalError?.code === 4902) {
          try {
            await provider.send('wallet_addEthereumChain', [
              {
                chainId: chainIdToHex(+id),
                chainName: chain.name,
                rpcUrls: chain.rpcUrls,
                blockExplorerUrls: chain.blockExplorerUrls || null,
                nativeCurrency: chain.nativeCurrency,
              },
            ]);
            setSelectedChainId(id);
            return id;
          } catch (addError) {
            console.error(addError);
          }
        } else {
          console.error(switchError);
        }
      }
    },
    [open, provider]
  );

  const getChainConfig = useCallback(
    (showNetwork = true) => {
      const chain = chains.find((item) => item.id == chainId);
      if (!chain) {
        if (showNetwork) open({ view: 'Networks' });
        return null;
      }

      return chain;
    },
    [chainId, open]
  );

  const safeCallContract = useCallback(
    async ({ name, method, args, showToast = true }) => {
      if (!provider || !isConnected) {
        open({ view: 'Connect' });
        toast.info('Please connect wallet');
        return [true, null];
      }

      const chain = chains.find((item) => item.id == chainId);
      if (!chain) {
        open({ view: 'Networks' });
        return [true, null];
      }

      const contractInstance = chain.contracts[name];
      if (!contractInstance) throw new Error(`Invalid contract name '${name}'`);

      const signer = provider.getSigner();
      const contract = contractInstance.connect(signer);

      // if (selectedChainId !== chainId) {
      //   const done = await switchChain(selectedChainId);
      //   if (!done) return;
      // }

      if (!method) return contract;

      try {
        const tx = await contract[method](...args);

        return [null, tx];
      } catch (err) {
        console.log('🚀 ~ file: WalletContext.jsx ~ line 139 ~ err', err);
        if (showToast) {
          handleTransactionError(err);
        }

        return [err, null];
      }
    },
    [chainId, isConnected, open, provider]
  );

  const value = useMemo(
    () => ({
      address,
      chainId,
      isConnected,
      provider,
      selectedChainId,
      switchChain,
      safeCallContract,
      getChainConfig,
    }),
    [
      address,
      chainId,
      isConnected,
      provider,
      selectedChainId,
      switchChain,
      safeCallContract,
      getChainConfig,
    ]
  );

  return (
    <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
  );
};
