import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import taikoStone from '../assets/taikoStone.gif';
import { useWallet } from '../hooks/useWallet';
import { handleTransactionError, toastPromise } from '../services/util';
import { useWeb3Modal } from '@web3modal/ethers5/react';
import { formatUnits, parseEther } from 'ethers/lib/utils';
import logoImg from '../assets/logo.png';

const EntrypassCard = (props) => {
  return (
    <svg
      className={props.className}
      onClick={props.onClick}
      width="292"
      height="409"
      viewBox="0 0 292 409"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 37.5V3H41" stroke="#FF5500" strokeWidth="6" />
      <path d="M6 370V404.5H41" stroke="#FF5500" strokeWidth="6" />
      <path d="M289 37.5V3H254" stroke="#FF5500" strokeWidth="6" />
      <path d="M289 371V405.5H254" stroke="#FF5500" strokeWidth="6" />
      <rect
        x="1.5"
        y="1.5"
        width="286"
        height="405"
        stroke="white"
        strokeOpacity="0.1"
        strokeWidth="3"
      />
    </svg>
  );
};

const NFTs = () => {
  const [totalRewards, setTotalRewards] = useState(0);
  const [stakedNFTsCount, setStakedNFTsCount] = useState(0);
  const [boughtNFTCount, setBoughtNFTCount] = useState(0);
  const [isMintLoading, setIsMintLoading] = useState(false);
  const [isStakeLoading, setIsStakeLoading] = useState(false);

  const wallet = useWallet();
  const { open } = useWeb3Modal();
  console.log('🚀 ~ Rewards ~ wallet:', wallet);
  const connectWallet = async () => {
    open({ view: wallet.isConnected ? 'Account' : 'Connect' });
  };

  const data = [
    { title: 'NFT Minted', value: boughtNFTCount, id: 1 },
    { title: 'NFT Stake', value: stakedNFTsCount, id: 2 },
    { title: 'Rewards Earned', value: totalRewards, id: 3 },
  ];

  const init = async () => {
    const config = wallet.getChainConfig(false);
    if (!config) return null;

    const [err, balance] = await wallet.safeCallContract({
      name: 'NFT',
      method: 'balanceOf',
      args: [wallet.address],
    });

    if (balance) {
      setBoughtNFTCount(balance.toNumber());
    }

    if (!config.features.apiStaking) {
      const [, stakedNfts] = await wallet.safeCallContract({
        name: 'NFT',
        method: 'getStakedNFTs',
        args: [wallet.address],
      });

      if (stakedNfts) {
        setStakedNFTsCount(stakedNfts.length);
      }
    }

    try {
      const [, rewards] = await wallet.safeCallContract({
        name: 'NFT',
        method: 'calculateTotalRewards',
        args: [wallet.address],
      });
      if (rewards) setTotalRewards(rewards.toNumber());
    } catch (error) {
      console.error(error);
      toast.error('Error occurred while fetching staking data');
    }
  };

  const mintNFT = async () => {
    setIsMintLoading(true);

    const config = wallet.getChainConfig();
    if (!config) return setIsMintLoading(false);

    const [err, tx] = await wallet.safeCallContract({
      name: 'NFT',
      method: 'safeMint',
      args: [
        wallet.address,
        {
          value: parseEther(String(config.features.mintValue)),
        },
      ],
    });

    if (tx) {
      await toastPromise(tx.wait(), {
        pending: 'Minting...',
        success: () => {
          setBoughtNFTCount((prev) => prev + 1);
          return 'NFT Minted successfully';
        },
        error: (err) => {
          console.error(err);
          return 'Minting failed';
        },
      });
    }

    setIsMintLoading(false);
  };

  const stakeNFT = async () => {
    const config = wallet.getChainConfig();
    if (!config) return null;
    setIsStakeLoading(true);

    const [err1, ownedNFTs] = await wallet.safeCallContract({
      name: 'NFT',
      method: 'tokensOfOwner',
      args: [wallet.address],
    });
    console.log('ownedNFTs', ownedNFTs);
    if (err1) return setIsStakeLoading(false);

    if (ownedNFTs?.length === 0) {
      toast.info('No NFTs owned to stake.');
      setIsStakeLoading(false);
      return;
    }

    const tokenIdToStake = ownedNFTs[0];

    const [err2, isApproved] = await wallet.safeCallContract({
      name: 'NFT',
      method: 'getApproved',
      args: [tokenIdToStake],
    });

    if (err2) return setIsStakeLoading(false);

    if (isApproved === false) {
      const [err3, approveTx] = await wallet.safeCallContract({
        name: 'NFT',
        method: 'approve',
        args: [config.contracts.NFT.address, tokenIdToStake],
      });

      if (err3) return setIsStakeLoading(false);

      await approveTx.wait();
    }

    const [err, tx] = await wallet.safeCallContract({
      name: 'NFT',
      method: 'stakeNFT',
      args: [
        tokenIdToStake,
        { value: parseEther(String(config.features.stakeValue)) },
      ],
    });

    if (tx) {
      await toastPromise(tx.wait(), {
        pending: 'Staking...',
        success: () => {
          setStakedNFTsCount((prev) => prev + 1);
          setBoughtNFTCount((prev) => prev - 1);
          return 'Staked Successfully';
        },
        error: (err) => {
          console.error(err);
          return 'Stake Failed';
        },
      });
    }

    setIsStakeLoading(false);
  };

  useEffect(() => {
    if (!wallet.address) return;
    init();
  }, [wallet.address, wallet.chainId]);

  return (
    <section className="reward-container min-h-screen w-full text-white">
      <nav
        className="h-28 z-50 fixed top-0 left-1/2 transform translate-x-[-50%] 2xl:container 
      w-full 2xl:px-0 px-10 flex justify-end items-center md:gap-x-8 gap-x-3 mb-8 text-white"
      >
        {/* Blazpay Logo */}
        <img
          src={logoImg}
          alt="Blazpay"
          className="absolute top-1/2 transform  translate-y-[-50%] left-0  md:h-fit sm:h-16 h-12"
        />

        {/* Connect Wallet */}
        <div
          className={`bg-gradient-to-r from-orange-500 to-yellow-400 sm:relative absolute bottom-0 w-fit flex justify-between items-center px-8  sm:py-2 py-1  border gap-x-3 rounded-full cursor-pointer`}
          onClick={connectWallet}
        >
          <p className="sm:text-base text-xs">
            {wallet.isConnected
              ? `${wallet.address.slice(0, 6)}...${wallet.address.slice(
                  wallet.address.length - 4
                )}`
              : 'Connect Wallet'}
          </p>
        </div>
      </nav>
      <div className="container mx-auto md:pt-36 px-20 min-h-screen">
        <div className="flex flex-col md:flex-row md:justify-between items-center space-y-4 md:space-y-0 py-10">
          <div className="flex  flex-col space-y-4 w-full md:w-2/3">
            <h1
              className="leading-[1.15] 
           xl:text-6xl  lg:text-5xl md:text-4xl sm:text-3xl text-2xl sm:px-0 px-8  font-semibold xl:w-[600px] lg:w-[500px] sm:w-[360px]  md:text-left text-center"
            >
              Mint and Stake to Earn Rewards
            </h1>
            <div className="flex space-x-4 md:pt-10">
              <button
                onClick={mintNFT}
                disabled={isMintLoading}
                className="bg-gradient-to-r from-orange-500 to-yellow-400 text-lg font-semibold px-6 py-2 rounded-full"
              >
                {isMintLoading ? 'Processing...' : 'Mint Now'}
              </button>
              <button
                onClick={stakeNFT}
                disabled={isStakeLoading}
                className="border border-orange-500 text-lg font-semibold px-6 py-2 rounded-full"
              >
                {isStakeLoading ? 'Processing...' : 'Stake Now'}
              </button>
            </div>
            <div className="flex flex-col md:pt-10 md:flex-row md:space-x-4">
              {data.map((item) => (
                <button
                  key={item.id}
                  className={`py-2 px-4 border ${
                    item.id === 3 ? 'border-orange-500' : 'border-gray-700'
                  } rounded-md`}
                >
                  {item.title}: {item.value}
                </button>
              ))}
            </div>
          </div>

          {/* Entrypass Card */}
          <div className="flex relative w-full md:w-1/3 pt-20">
            <EntrypassCard className="lg:h-[448px] lg:w-[389px] h-[260px] w-[200px]" />
            <img
              src={taikoStone}
              alt=""
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:h-[350px] lg:w-[450px] h-[220px] w-[150px] "
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NFTs;
