import { ToastContainer } from 'react-toastify';
import { WalletProvider } from './contexts/WalletContext.jsx';
import 'react-toastify/dist/ReactToastify.css';
import NFTs from './components/NFTs.jsx';
import './services/walletConnect.js';
const App = () => {
  return (
    <WalletProvider>
      <ToastContainer position="top-right" theme="dark" autoClose={10000} />
      <NFTs />
    </WalletProvider>
  );
};

export default App;
